import React from 'react';

import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

function CustomMaskCep(props) {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask="99999-999"
    />
  );
}

CustomMaskCep.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default CustomMaskCep;
